import gql from 'graphql-tag'

export const GET_SHIPPING = gql`
  query GetShipping {
    getShipping {
      id
      currency
      zones {
        id
        name
        countries {
          code
          name
          restOfWorld
        }
        rates {
          id
          name
          price {
            amount
            currency
          }
        }
      }
    }
  }
`

export const GET_SHIPPING_CURRENCIES = gql`
  query GetShippingCurrencies {
    getShippingCurrencies {
      profiles {
        id
        currency
        zones {
          id
          name
          countries {
            code
            name
            restOfWorld
          }
          rates {
            id
            name
            price {
              amount
              currency
            }
          }
        }
      }
    }
  }
`
