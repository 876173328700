import {
  SYNC_SHIPPING_CURRENCIES,
  UPDATE_SHIPPING,
  UPDATE_SHIPPING_CURRENCIES,
} from '@/graphql/mutation/shipping'
import { GET_SHIPPING, GET_SHIPPING_CURRENCIES } from '@/graphql/query/shipping'
import apolloClient from '@/services/apollo'
import {
  MutationSyncShippingCurrenciesArgs,
  MutationUpdateShippingArgs,
  MutationUpdateShippingCurrenciesArgs,
  ShippingCurrenciesResponse,
  ShippingProfile,
  ShippingProfileFormInput,
} from '../../../shared/types/types'

export const getShipping = async (): Promise<ShippingProfile> => {
  const { data } = await apolloClient.query<{ getShipping: ShippingProfile }>({
    query: GET_SHIPPING,
    fetchPolicy: 'no-cache',
  })
  console.log(
    '🚀 ~ file: shipping.ts:21 ~ getShipping ~ data',
    JSON.stringify(data, null, 2)
  )

  return data.getShipping
}

export const getShippingCurrencies = async (): Promise<ShippingCurrenciesResponse> => {
  const { data } = await apolloClient.query<{
    getShippingCurrencies: ShippingCurrenciesResponse
  }>({
    query: GET_SHIPPING_CURRENCIES,
    fetchPolicy: 'no-cache',
  })

  return data.getShippingCurrencies
}

export const updateShipping = async (
  input: ShippingProfileFormInput
): Promise<ShippingProfile | undefined> => {
  const { data } = await apolloClient.mutate<
    {
      updateShipping: ShippingProfile | undefined
      fetchPolicy: 'no-cache'
    },
    MutationUpdateShippingArgs
  >({
    mutation: UPDATE_SHIPPING,
    variables: {
      input,
    },
  })

  return data?.updateShipping
}

export const syncShippingCurrencies = async (
  shippingId: string
): Promise<ShippingProfile[] | undefined> => {
  const { data } = await apolloClient.mutate<
    {
      syncShippingCurrencies: ShippingProfile[] | undefined
      fetchPolicy: 'no-cache'
    },
    MutationSyncShippingCurrenciesArgs
  >({
    mutation: SYNC_SHIPPING_CURRENCIES,
    variables: {
      shippingId,
    },
  })

  return data?.syncShippingCurrencies
}

export const updateShippingCurrencies = async (
  shippingId: string,
  input: ShippingProfileFormInput[]
): Promise<ShippingCurrenciesResponse | undefined> => {
  const { data } = await apolloClient.mutate<
    {
      updateShippingCurrencies: ShippingCurrenciesResponse | undefined
      fetchPolicy: 'no-cache'
    },
    MutationUpdateShippingCurrenciesArgs
  >({
    mutation: UPDATE_SHIPPING_CURRENCIES,
    variables: {
      shippingId,
      input,
    },
  })

  return data?.updateShippingCurrencies
}
