




































































































































import Vue from 'vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {
  getShipping,
  getShippingCurrencies,
  syncShippingCurrencies,
  updateShipping,
  updateShippingCurrencies,
} from '@/lib/shipping'
import CurrencyInput from '@/components/shared/CurrencyInput.vue'
import CountriesModal from '@/components/shared/CountriesModal.vue'
import { data as countriesData } from '@/assets/countries.json'
import CurrenciesModal from '@/components/shared/CurrenciesModal.vue'
import {
  CurrencyEnum,
  Price,
  ShippingProfile,
  ShippingRate,
  ShippingZone,
  ShippingZoneInput,
} from '../../../../../shared/types/types'
import { formatPrice } from '@/lib/utils'

export default Vue.extend({
  name: 'Shipping',
  components: {
    NavigateBack,
    CurrencyInput,
  },
  data() {
    return {
      shippingZones: [],
      zoneIdToEdit: '',
      currencies: [],
      savingZoneId: '',
      sync: false,
      loaded: false,
    } as {
      shippingZones: ShippingZone[]
      zoneIdToEdit: string
      currencies: ShippingProfile[]
      savingZoneId: string | null
      sync: boolean
      loaded: boolean
    }
  },
  async mounted() {
    const shippingData = await getShipping()
    console.log(
      '🚀 ~ file: Shipping.vue:183 ~ mounted ~ shippingData',
      JSON.stringify(shippingData, null, 2)
    )
    this.shippingZones = shippingData.zones.slice()

    const currencies = await getShippingCurrencies()
    this.currencies = currencies.profiles.slice()

    this.loaded = true
  },
  methods: {
    getCountryFlag(countryCode: string) {
      return getUnicodeFlagIcon(countryCode)
    },
    isCustomZone(zone: ShippingZone) {
      return !zone.countries?.[0]?.restOfWorld
    },
    setZoneIdToEdit(id: string) {
      this.zoneIdToEdit = id
    },
    isEdittingZone(id: string) {
      return this.zoneIdToEdit === id
    },
    handleCountryEdit(id: string) {
      const zoneIndex = this.shippingZones.findIndex((zone) => zone.id === id)
      const zone = this.shippingZones[zoneIndex]

      this.$buefy.modal.open({
        parent: this,
        component: CountriesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          selectedCountryCodes: zone.countries.map((country) => country.code),
        },
        events: {
          save: async (
            selectedCountryCodes: string[],
            closeModal: () => void
          ) => {
            const newZone = {
              ...zone,
              countries: selectedCountryCodes.map((code) => ({
                code,
                name: countriesData.find((country) => country.code === code)!
                  .name,
                restOfWorld: false,
              })),
            }

            this.shippingZones.splice(zoneIndex, 1, newZone)
            closeModal()
          },
        },
      })
    },
    updateTitleForZone(index: number, value: string) {
      Vue.set(this.shippingZones, index, {
        ...this.shippingZones[index],
        name: value,
      })
    },
    updateRateNameForZone(index: number, rateId: string, value: string) {
      const rateIndex = this.shippingZones[index].rates.findIndex(
        (rate) => rate.id === rateId
      )
      const rate = this.shippingZones[index].rates[rateIndex]

      const newRate = {
        ...rate,
        name: value,
      }

      const newRates = this.shippingZones[index].rates.slice()
      newRates.splice(rateIndex, 1, newRate)

      const shippingZone = { ...this.shippingZones[index], rates: newRates }

      Vue.set(this.shippingZones, index, shippingZone)
    },
    updateRateValueForZone(index: number, rateId: string, value: number) {
      const rateIndex = this.shippingZones[index].rates.findIndex(
        (rate) => rate.id === rateId
      )
      const rate = this.shippingZones[index].rates[rateIndex]

      const newRate: ShippingRate = {
        ...rate,
        price: {
          ...rate.price,
          amount: value,
        },
      }

      const newRates = this.shippingZones[index].rates.slice()
      newRates.splice(rateIndex, 1, newRate)

      const shippingZone = { ...this.shippingZones[index], rates: newRates }

      Vue.set(this.shippingZones, index, shippingZone)
    },
    addRate(index: number) {
      const rate = {
        id: window.crypto.randomUUID(),
        name: '',
        price: {
          amount: 0,
          currency: CurrencyEnum.Usd,
        },
      }

      const newRates = [...this.shippingZones[index].rates, rate]
      const shippingZone = { ...this.shippingZones[index], rates: newRates }

      Vue.set(this.shippingZones, index, shippingZone)
    },
    deleteRate(index: number, rateId: string) {
      const newRates = this.shippingZones[index].rates.filter(
        (rate) => rate.id !== rateId
      )

      const shippingZone = { ...this.shippingZones[index], rates: newRates }

      Vue.set(this.shippingZones, index, shippingZone)
    },
    canEdit(zoneId: string, rateId: string) {
      return this.currencies[0].zones.some((zone) =>
        zone.rates.some((rate) => rate.id === rateId)
      )
    },
    async saveZone(id: string) {
      this.savingZoneId = id

      await updateShipping({
        currency: CurrencyEnum.Usd,
        zones: this.shippingZones as ShippingZoneInput[],
      })

      this.savingZoneId = null
      this.zoneIdToEdit = ''
    },
    isSaving(id: string) {
      return this.savingZoneId === id
    },
    async handleSync() {
      this.sync = true
      await syncShippingCurrencies('default')
      this.sync = false
    },
    handleCreateZone() {
      const zoneId = window.crypto.randomUUID()

      this.shippingZones.push({
        id: zoneId,
        name: '',
        countries: [],
        rates: [
          {
            id: window.crypto.randomUUID(),
            name: '',
            price: {
              currency: CurrencyEnum.Usd,
              amount: 0,
            },
          },
        ],
      })

      this.zoneIdToEdit = zoneId
    },
    formatPrice(price: Price) {
      return formatPrice(price)
    },
    handleCurrency(zoneId: string, rateId: string) {
      const profiles = this.currencies.slice()
      const currencies: Price[] = this.currencies.slice().map((profile) => {
        const zone = profile.zones.find((zone) => zone.id === zoneId)
        const rate = zone?.rates.find((rate) => rate.id === rateId)

        return {
          currency: profile.currency,
          amount: rate?.price?.amount || 0,
        }
      })

      this.$buefy.modal.open({
        parent: this,
        component: CurrenciesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          currencies,
          async save(newCurrencies: Price[]) {
            const profilesToSave = profiles.slice().map((profile) => {
              const newProfile = profile

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              delete newProfile.id

              const zone = newProfile.zones.find((zone) => zone.id === zoneId)
              const rate = zone?.rates?.find((rate) => rate.id === rateId)
              const newRate = newCurrencies.find(
                (currency) => currency.currency === newProfile.currency
              )
              if (rate) {
                rate.price = newRate
              }

              return JSON.parse(JSON.stringify(newProfile))
            })

            await updateShippingCurrencies('default', profilesToSave)
          },
        },
      })
    },
  },
})
