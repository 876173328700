import gql from 'graphql-tag'

export const UPDATE_SHIPPING = gql`
  mutation UpdateShipping($input: ShippingProfileFormInput!) {
    updateShipping(input: $input) {
      id
      currency
      zones {
        id
        name
        countries {
          code
          name
          restOfWorld
        }
        rates {
          id
          name
          price {
            amount
            currency
          }
        }
      }
    }
  }
`

export const SYNC_SHIPPING_CURRENCIES = gql`
  mutation syncShippingCurrencies($shippingId: ID!) {
    syncShippingCurrencies(shippingId: $shippingId) {
      id
      currency
      zones {
        id
        name
        countries {
          code
          name
          restOfWorld
        }
        rates {
          id
          name
          price {
            amount
            currency
          }
        }
      }
    }
  }
`

export const UPDATE_SHIPPING_CURRENCIES = gql`
  mutation updateShippingCurrencies(
    $shippingId: ID!
    $input: [ShippingProfileFormInput!]!
  ) {
    updateShippingCurrencies(shippingId: $shippingId, input: $input) {
      profiles {
        id
        currency
        zones {
          id
          name
          countries {
            code
            name
            restOfWorld
          }
          rates {
            id
            name
            price {
              amount
              currency
            }
          }
        }
      }
    }
  }
`
